import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification()
import { commonService } from '../../../_services/common.service';
import errHandle from '../../../handleError/errorHandling'
import route from "../../../router";

const osfurl = `wss://skypro.skybroking.com/NorenWSWEBCODIFI/`
const osfsource = 'WEB'

let socket_1;
let state = {
    connectionStatus: false,
    wsSession:'',
    heartBeatInterval: '',
    webSocketCount:0,
    wsSessionLoader: false
}

let actions = {
    async getWsSession({ state, rootGetters, dispatch, commit }, payload) {
        commit('setWsSessionLoader', true)
        try {
            
            let response = await commonService.getWsSession()
            if (response.status === 200 && response.data.status == 'Ok') {
                await commit('setWsSession', response.data.result[0]?.key)
                    dispatch('connect', 'createSession')
            }
            commit('setWsSessionLoader', false)
        } catch (error) {
            commit('setWsSessionLoader', false)
            errHandle.statusCodeCheck(error)
        }
    },

    async connect({ state,commit, dispatch, rootGetters }) {
        socket_1 = new WebSocket(osfurl);
        socket_1.onopen = async function () {
            await dispatch("connectionRequest");
        };
        socket_1.onmessage = function (msg) {
            var responseFeed = JSON.parse(msg.data);
            if (!!responseFeed.t && responseFeed.t == 'ck' && responseFeed.s == 'OK') {
                commit('setConnectionStatus', true)
                dispatch('subsOrderUpdate')
                state.heartBeatInterval = setInterval(() => dispatch('sendHB'), 10000)
            }
            if(responseFeed.t == 'om' && responseFeed.hasOwnProperty('norenordno') && state.connectionStatus) {
                dispatch('orderNotification', responseFeed)
                // console.log('delayCheck', new Date().toLocaleTimeString(), ':', new Date().getMilliseconds(), "OM");
            }
        };

        socket_1.onerror = function (err) {
            socket_1.onclose()
        };

        socket_1.onclose = function (err) {
            socket_1.close()
            dispatch('reconnectRequest')
        };
    },

    async send({ state, commit, dispatch }, msg) {
        if (!!socket_1.readyState && socket_1.readyState == 1) {
            try {
                socket_1.send(msg);
            } catch (err) {
                console.error(err);
            }
        } else if (!!socket_1.readyState && socket_1.readyState == 0) {
            setTimeout(() => { socket_1.send(msg); }, 900);

        }
    },

    async connectionRequest({ dispatch, rootGetters }) {
        var token = state.wsSession
        var userId = await rootGetters['login/getUCC'];

        var initCon = {
            susertoken: token,
            t: "c",
            actid: userId,
            uid: userId,
            source: osfsource
        }
        dispatch("send", JSON.stringify(initCon));
    },

    async establishConnection({ state, dispatch }, payload) {
        if (state.connectionStatus == false) {
            await dispatch("connectionRequest");
        }
        await dispatch("send", JSON.stringify(payload));
    },


    async websocketClose({ state, commit, dispatch, rootGetters }, payload) {
        socket_1 ? socket_1.close() : ''
        commit('reset_state')
    },

    async subsOrderUpdate({ state, commit, dispatch, rootGetters }) {
        let json = {
            t: 'o',
            actid: rootGetters['login/getUCC'],
        };
        await dispatch('establishConnection', json)
    },

    async unSubsOrderUpdate({ state, commit, dispatch, rootGetters }) {
        let json = {
            t: 'uo'
        };
        await dispatch('establishConnection', json)
    },

    async sendHB({ state, commit, dispatch, rootGetters }) {
        let json = {k: "","t":"h"}
        await dispatch('establishConnection', json)
    },

    async orderNotification({ state, commit, dispatch, rootGetters }, feed) {
        let tradSym = feed?.tsym
        let trantype = feed?.trantype == 'B' ? 'BUY' : 'SELL'
        let ordNo = feed?.norenordno
        let status = feed?.status
        let qty = feed?.qty
        let price = feed?.prc
        let prcType = feed?.prctyp
        let rejReason = feed?.rejreason
        let avgPrc = feed?.avgprc
        let path = route.currentRoute.value.path
        let query = route.currentRoute.value.query
        let reportType = feed?.reporttype.toLowerCase()
        // notify({ group: "auth",  clean: true });
        if(status == 'REJECTED' || reportType.includes('rejected')) {
            notify({ group: 'auth', type: 'error', title: `${tradSym} - ${trantype} - ${ordNo}`, text: `${status}` })
        } else if(status == 'OPEN') {
            notify({ group: 'auth', type: 'warn', title: `${tradSym} - ${trantype} - ${ordNo}`, text: `${status}` })
        } else if(status == 'COMPLETE') {
            notify({ group: 'auth', type: 'success', title: `${tradSym} - ${trantype} - ${ordNo}`, text: `${status}` })
        } else if(status == 'CANCELED') {
            notify({ group: 'auth', type: 'cancel', title: `${tradSym} - ${trantype} - ${ordNo}`, text: `${status}` })
        }
        // if(reportType == 'newack' || reportType == 'new') return 
        if(reportType == 'newack') return 
        if(path == '/order') {
            dispatch('order/getUserOrders','', {root: true})
        }
        if((path == '/position') && (status == 'COMPLETE' || status == 'OPEN' || status == 'CANCELED')) {
            dispatch('positions/getUserPositions','', {root: true})
            // let count = 0;
            // async function repeatFunction() {
            //     if (count < 4) {
            //         dispatch('positions/getUserPositions','', {root: true})
            //         count++;
            //         setTimeout(repeatFunction, 100); // Repeat the function every 100ms
            //     }
            // }
            // await repeatFunction();
        }
        if(status == 'COMPLETE' && (path == '/order' && query == 2)) { 
            dispatch('order/getTradeBook','', {root: true})
        }
    },
    async backGroundCheck({state, dispatch}, payload){
        if(navigator.onLine){
            if(payload == 'connect' && socket_1 && socket_1.readyState != 1){
                if(!state.wsSessionLoader){
                    await  dispatch('websocketClose')
                    await dispatch('getWsSession', 'backGroundCheck')
                }
            }
        
        } else{
            console.error(`You Don't have network, Please check your network connection...`)
        }
    },
    reconnectRequest({state, commit, dispatch, rootGetters}, payload){
        commit("setWebSocketCount", state.webSocketCount + 1);
          if (
            rootGetters['login/getAccessToken'] &&
            route.currentRoute.value.path !== "/" &&
            state.webSocketCount < 3
          ) {
            commit("setConnectionStatus", false);
            dispatch("connect");
          }
    }
}

const mutations = {
    setConnectionStatus(state, payload) {
        state.connectionStatus = payload
    },
    setWsSession(state, payload) {
        state.wsSession = payload
    },
    reset_state(state) {
        Object.assign(state,{
            connectionStatus: false,
            wsSession:'',
            heartBeatInterval: '',
            webSocketCount: 0,
            wsSessionLoader: false
        });
    },
    setWebSocketCount(state,payload){
        state.webSocketCount = payload
    },
    setWsSessionLoader(state, payload){
        state.wsSessionLoader = payload
    }
}

const getters = {
    getConnectionStatus: (state) => state.connectionStatus,
    getWsSession: (state) => state.wsSession,
}

const osf_ws = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default osf_ws