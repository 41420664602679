
import store from '../../../_store'
import { env } from '../../../_helpers/env_index'
import router from '../../../router'
export default {
  methods: {
    checkMethod(data) {
      if (router.currentRoute.value.path != '/chart') {
        router.push({ name: "chart" })
      }
      this.refresh(data)
    },
    refresh(item) {
      var currentChart = item;
      localStorage.setItem("chartData", JSON.stringify(item));
      var token = currentChart.token;
      var exchange = currentChart.exchIndex && currentChart.exchIndex.includes('index') ? currentChart.exchIndex : currentChart.exchange;
      var symbol = currentChart.exchIndex && currentChart.exchIndex.includes('index') ? currentChart.symbol : currentChart.tradingSymbol;
      let indicators = store.state.chart.chartIndicators
      var data = {
        symbol: symbol,
        interval: "1D",
        token: token,
        exchange: exchange,
        clientId: "GoPocket",
        userId: store.state.login.UCC,
        locale: "en",
        uid: "tradingview_cf3ee",
        chartsStorageUrl: env().CHARTURL,
        chartsStorageVer: "1.1",
        autoSaveDelay: 5,
        debug: false,
        timezone: "Asia/Kolkata",
        loginType: "web",
        showPopout: true,
        isSymbolSearch: false,
        theme: store.state.pref.isTheme == '1' ? 'dark' : 'light',
        baseUrl: env().BASEURL,
        endpoint: env().endpoint,
        session: store.state.login.accessToken,
        preloadProps : {
          interval: "1D",
          indicators: indicators,
        }
      }
      store.commit('chart/setTardeData', data)
      let destination = document.getElementById("chartFrame");
      if (destination && destination.contentWindow && destination.src) {
        let message = {
          id: 'changeSymbol',
          message: "?data=" + JSON.stringify(data)
        }
        destination.contentWindow?.postMessage(message, "*");
      } else {
        let tempUrl = window.location.protocol == 'https:' ?  env().depolyedUrl :  env().localUrl
        store.commit("chart/setChartUrl", tempUrl + "?data=" + JSON.stringify(data))
        store.commit("chart/setRefreshArray", destination)
      }
    },
  }
}