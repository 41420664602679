import { commonService } from '../../../_services/common.service';
import errHandle from '../../../handleError/errorHandling'
import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification()

const state = {
    alertsList: '',
    isAlertDialog: false,
    alertType: 'Create',
    modifyData: '',
    alertData: '',
    ltpValue: '',
    exchangeStatus: '',
    exchangeMsg: '',
    marketTabs: [
        { name: 'NSE', id: 0, active: true },
        { name: 'BSE', id: 1, active: false },
        { name: 'MCX', id: 2, active: false },
    ],
    exchStatusLoader: false,
    exchMsgLoader: false
}

const actions = {
    async getAlerts({state, commit, dispatch, rootGetters }) {
        try {
            commit('setLoader', true, { root: true })
            let json = {
                "userId": rootGetters['login/getUCC'],
            }
            let resp = await commonService.getAlerts(json)
            if (resp.data.status == "Ok" && resp.data.message == "Success") {
                dispatch('setAlerts', resp.data.result)
                state.isAlertDialog = false
            } else {
                
                dispatch('setAlerts', [])
            }
        } catch (error) {
            commit('setLoader', false, { root: true })
            errHandle.statusCodeCheck(error)
        }
        commit('setLoader', false, { root: true })
        commit('setCancelAlertDialog', false, { root: true })
    },

    async createAlert({ state,commit, dispatch, rootGetters }, payload) {
        try {
            let resp = await commonService.createAlert(payload)
            if (resp.data.status == "Ok" && resp.data.message == "Success") {
                notify({ group: 'auth', type: 'success', title: `Alert Created` })
                dispatch('getAlerts')
            } else {
                notify({ group: 'auth', type: 'error', title: `${response.data.message}` })
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
    },

    async cancelAlert({ state, dispatch, commit, rootGetters }, payload) {
        try {
            let resp = await commonService.cancelAlert(payload)
            if (resp.data.status == "Ok" && resp.data.message == "Success") {
                commit('setCancelAlertDialog', false, { root: true })
                dispatch('getAlerts')
                notify({ group: 'auth', type: 'success', title: `Alert Cancelled` })
            } else {
                notify({ group: 'auth', type: 'error', title: `${response.data.message}` })
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
    },

    async modifyAlert({ state,dispatch, commit, rootGetters }, payload) {
        try {
            let resp = await commonService.modifyAlert(payload)
            if (resp.data.status == "Ok" && resp.data.message == "Success") {
                dispatch('getAlerts')
            } else {
                notify({ group: 'auth', type: 'error', title: `${response.data.message}` })
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
    },
    
    async setAlerts({ state, dispatch, rootGetters }, payload) {
        state.alertsList = payload
        dispatch('order/setOrdersTabsCount', {
            open: rootGetters['order/getOpenOrders'],
            exec: rootGetters['order/getExcutedOrders'], 
            alerts: state.alertsList,
            tradeBook : rootGetters['order/getTradeBook'],
            positions: rootGetters['positions/getPositionsData'],
        }, { root: true })
    },

    async getExchangeStatus({ state, commit, rootGetters }, payload) {
        try {
            commit('setExchStatusLoader', true)
            let resp = await commonService.getExchangeStatus(payload)
            if (resp.data.status == "Ok" && resp.data.message == "Success") {
                commit('setExchangeStatus', resp.data.result)
            } else if(resp.data.message != 'Guest User') {
                notify({ group: 'auth', type: 'error', title: `${resp.data.message}` })
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        finally {
            commit('setExchStatusLoader', false)
        }
    },

    async getExchangeMsg({ state, commit, rootGetters }, payload) {
        try {
            commit('setExchMsgLoader', true)
            let resp = await commonService.getExchangeMsg(payload)
            if (resp.data.status == "Ok" && resp.data.message == "Success") {
                commit('setExchangeMsg', resp.data.result)
            } else if(resp.data.message != 'Guest User') {
                notify({ group: 'auth', type: 'error', title: `${resp.data.message}` })
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        finally {
            commit('setExchMsgLoader', false)
        }
    }
};

const mutations = {
    setIsAlertDialog(state, payload) {
        state.isAlertDialog = payload.boolean
        state.alertType = payload?.type
        state.alertData = payload?.data
    },
    setCurrentAlertData(state, payload) {
        state.modifyData = payload
    },
    setLtpValue(state, payload) {
        state.ltpValue = payload
    },
    setExchangeStatus(state, payload) {
        state.exchangeStatus = payload
    },
    setExchangeMsg(state, payload) {
        state.exchangeMsg = payload
    },
    setMarketTabs(state, payload) {
        state.marketTabs = payload
    },
    setExchStatusLoader(state, payload) {
        state.exchStatusLoader = payload
    },
    setExchMsgLoader(state, payload) {
        state.exchMsgLoader = payload
    },
    reset_state(state) {
        Object.assign(state, {
            alertsList: '',
            isAlertDialog: false,
            alertType: 'Create',
            modifyData: '',
            alertData: '',
            ltpValue: '',
            exchangeStatus: '',
            exchangeMsg: '',
            marketTabs: [
                { name: 'NSE', id: 0, active: true },
                { name: 'BSE', id: 1, active: false },
                { name: 'MCX', id: 2, active: false },
            ],
            exchStatusLoader: false,
            exchMsgLoader: false
        });
    },
};

const getters = {
    getAlerts: state => state.alertsList,
    getIsAlertDialog: state => state.isAlertDialog,
    getAlertType: state => state.alertType,
    getModifyData: state => state.modifyData,
    getAlertCurrentData: state => state.alertData,
    getLtpValue: state => state.ltpValue,
    getExchangeStatus: state => state.exchangeStatus,
    getExchangeMsg: state => state.exchangeMsg,
    getMarketTabs: state => state.marketTabs,
    getExchStatusLoader: state => state.exchStatusLoader,
    getExchMsgLoader: state => state.exchMsgLoader
};

const alert = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default alert