import { commonService } from '../../../_services/common.service';
import errHandle from '../../../handleError/errorHandling'
import common from '../../../mixins/common';

function findNearest(arr, num) {
    let nearest = null;
    let minDiff = Infinity;

    for (let i = 0; i < arr.length; i++) {
        const diff = Math.abs(Number(num) - Number(arr[i].strikeprice));

        if (diff < minDiff) {
            minDiff = diff;
            nearest = Number(arr[i].strikeprice);
        }
    }

    return nearest;
}

const state = {
    ocd: '',
    ocdFull: '',
    underlyingList: [],
    underlyingExpiryList: [],
    interval: 10,
    instrument: '',
    expiry: '',
    nearestStrike: '',
    sco: 0,
    bco: 0,
    spo: 0,
    bpo: 0,
    spotPosition: 'top',
    isCallFromOut: false,
    insExp: '',

    // 
    ocdBig: '',
    ocdFullBig: '',
    underlyingListBig: [],
    underlyingExpiryListBig: [],
    intervalBig: 10,
    instrumentBig: '',
    expiryBig: '',
    nearestStrikeBig: '',
    scoBig: 0,
    bcoBig: 0,
    spoBig: 0,
    bpoBig: 0,
    spotPositionBig: 'top',
    isCallFromOutBig: false,
    insExpBig: '',
    isOcLoader: false,
    active_OC_exch: 'nse_fo',
    exchangeList: [
    ],
}
const actions = {
    async getOptionChain({ state, commit, dispatch, rootGetters }, payload) {
        try {
            if(payload.type != 'full') {
                commit('watchlist/setMwLoader', true, { root: true } )
            } else {
                commit('setIsOcLoader', true)
            }
            let exp = payload.type == 'full' ? state.expiryBig : state.expiry
            let ins = payload.type == 'full' ? state.instrumentBig : state.instrument
            let json = {
                "underlying": ins,
                "expiry": exp || payload.expiry,
                "interval": payload.type == 'full' ? state.intervalBig : state.interval,
                exch: state.active_OC_exch
                
            }
            let resp = await commonService.getOptionChain(json)
            if (resp.data.status == "Ok" && resp.status == 200 && resp.data.message == "Success" && resp.data.result && resp.data.result.length > 0) {
                let spotLTP = resp.data.result[0].spotLTP ? resp.data.result[0].spotLTP : 0
                if(payload.type != 'full') {
                    commit('setOCD', resp.data.result[0].data)
                    commit('setOCDFull', resp.data.result[0])
                    commit('setNearestStrike', spotLTP)
                    dispatch('subsOptionChain', resp.data.result[0])
                } else {
                    commit('setOCDBig', resp.data.result[0].data)
                    commit('setOCDFullBig', resp.data.result[0])
                    commit('setNearestStrikeBig', spotLTP)
                    dispatch('subsOptionChainFull', resp.data.result[0])
                }
            } else {
                commit('setOCD', [])
                commit('setOCDFull', [])

                commit('setOCDBig', [])
                commit('setOCDFullBig', [])
            }
        } catch (error) {
            commit('watchlist/setMwLoader', false, { root: true } )
            commit('setIsOcLoader', false)
            errHandle.statusCodeCheck(error)
        }
        commit('watchlist/setMwLoader', false, { root: true } )
        commit('setIsOcLoader', false)
    },

    async getUnderlying({state, commit, dispatch, rootGetters }, payload) {
        let json = {
            exch: state.active_OC_exch
        }
        try {
            if(payload.type != 'full') {
                commit('watchlist/setMwLoader', true, { root: true } )
            }
            
            let resp = await commonService.getUnderlying(json)
            if (resp.data.status == "Ok" && resp.data.message == "Success" && resp.data.result && resp.data.result.length > 0) {
                if(payload.type == 'full') {
                    await commit('setUnderlyingListBig', resp.data.result[0].list_underlying)
                } else {
                    await commit('setUnderlyingList', resp.data.result[0].list_underlying)
                }
            } else {
                commit('setUnderlyingList', [])
                commit('setUnderlyingListBig', [])
            }
        } catch (error) {
            commit('watchlist/setMwLoader', false, { root: true } )
            errHandle.statusCodeCheck(error)
        }
        commit('watchlist/setMwLoader', false, { root: true } )
    },

    async getUnderlyingExpiry({ state, commit, dispatch, rootGetters }, payload) {
        try {
            let json = {
                "underlying": payload.insName,
                exch: state.active_OC_exch
            }
            if(payload.type != 'full') {
                commit('watchlist/setMwLoader', true, { root: true } )
            }
            
            let resp = await commonService.getUnderlyingExpiry(json)
            if (resp.data.status == "Ok" && resp.data.message == "Success" && resp.data.result && resp.data.result.length > 0) {
                if(payload.type != 'full') {
                    commit('setUnderlyingExpiryList', resp.data.result[0].underlying_expiry)
                } else {
                    commit('setUnderlyingExpiryListBig', resp.data.result[0].underlying_expiry)
                }
                
            } else {
                commit('setUnderlyingExpiryList', [])
                commit('setUnderlyingExpiryListBig', [])
            }
        } catch (error) {
            commit('watchlist/setMwLoader', false, { root: true } )
            errHandle.statusCodeCheck(error)
        }
        commit('watchlist/setMwLoader', false, { root: true } )
    },

    async subsOptionChain({ state, rootGetters, dispatch }, payload) {
        let exch = ( payload && payload.exchange ) ? (payload.exchange == 'nse_fo' ? 'NSE' : payload.exchange == 'cde_fo' ? 'CDS' : payload.exchange == 'mcx_fo' ? 'MCX' : '') : 'NSE'
        let channelExch = ( payload && payload.exchange ) ? (payload.exchange == 'nse_fo' ? 'NFO' : payload.exchange == 'cde_fo' ? 'CDS' : payload.exchange == 'mcx_fo' ? 'MCX' : '') : 'NFO'
        
        if (rootGetters['ws/getConnectionStatus']) {
            let channel = ''
            if(payload) {
                if(payload.spotToken) {
                    channel = `${exch}|${payload.spotToken}#`
                }
                localStorage.setItem('ocTokens' , JSON.stringify({spotToken : `${exch}|${payload.spotToken}`}))
                if(payload?.data?.length) {
                    await payload?.data.forEach(el => {
                        channel += `${channelExch}|${el.CE['token']}#${channelExch}|${el.PE['token']}#`
                    });
                }
            }
            var tempData = {
                subscribe: channel,
                where: 'optionchain',
                data:[]
            }
            dispatch("ws/websocketSubscription", tempData, { root: true })
        }
    },

    async subsOptionChainFull({ state, rootGetters, dispatch }, payload) {
        let exch = ( payload && payload.exchange ) ? (payload.exchange == 'nse_fo' ? 'NSE' : payload.exchange == 'cde_fo' ? 'CDS' : payload.exchange == 'mcx_fo' ? 'MCX' : '') : 'NSE'
        let channelExch = ( payload && payload.exchange ) ? (payload.exchange == 'nse_fo' ? 'NFO' : payload.exchange == 'cde_fo' ? 'CDS' : payload.exchange == 'mcx_fo' ? 'MCX' : '') : 'NFO'
        
        if (rootGetters['ws/getConnectionStatus']) {
            let channel = ''
            if(payload) {
                channel = `${exch}|${payload.spotToken}#`
                localStorage.setItem('ocTokensFull' , JSON.stringify({spotToken : `${exch}|${payload.spotToken}`}))
                if(payload?.data?.length) {
                    await payload?.data.forEach(el => {
                        channel += `${channelExch}|${el.CE['token']}#${channelExch}|${el.PE['token']}#`
                    });
                }
            }
            var tempData = {
                subscribe: channel,
                where: 'optionchainfull',
                data:[]
            }
            dispatch("ws/websocketSubscription", tempData, { root: true })
        }
    }
};

const mutations = {
    setOCD(state, payload) {
        state.ocd = payload
        common.gvalCalcset()
    },
    setOCDBig(state, payload) {
        state.ocdBig = payload
        common.gvalCalcsetBig()
    },
    setOCDFull(state, payload) {
        state.ocdFull = payload
        let tickers = window.tickers
        let tokenList = JSON.parse(localStorage.getItem('ocTokens'))
        if(tickers && tokenList?.spotToken) {
            state.ocdFull.spotLTP = tickers[tokenList.spotToken]?.lp ? tickers[tokenList.spotToken]?.lp : tickers[tokenList.spotToken]?.c
        }
    },
    setOCDFullBig(state, payload) {
        state.ocdFullBig = payload
        let tickers = window.tickers
        let tokenList = JSON.parse(localStorage.getItem('ocTokensFull'))
        if(tickers && tokenList?.spotToken) {
            state.ocdFullBig.spotLTP = tickers[tokenList.spotToken]?.lp ? tickers[tokenList.spotToken]?.lp : tickers[tokenList.spotToken]?.c
        } 
    },
    setUnderlyingList(state, payload) {
        state.underlyingList = payload
        if(!state.isCallFromOut) {
            this.commit('oc/setInstrument', payload[0])
        }
    },
    setUnderlyingListBig(state, payload) {
        state.underlyingListBig = payload
        if(!state.isCallFromOutBig) {
            this.commit('oc/setInstrumentBig', payload[0])
        }
    },
    setUnderlyingExpiryList(state, payload) {
        state.underlyingExpiryList = payload
    },
    setUnderlyingExpiryListBig(state, payload) {
        state.underlyingExpiryListBig = payload
    },
    setInstrument(state, payload) {
        state.instrument = payload
    },
    setInstrumentBig(state, payload) {
        state.instrumentBig = payload
    },
    setExpiry(state, payload) {
        state.expiry = payload
    },
    setExpiryBig(state, payload) {
        state.expiryBig = payload
    },
    setNearestStrike(state, spotLTP) {
        state.nearestStrike = findNearest(state.ocd, spotLTP)
        state.spotPosition = state.nearestStrike > state.ocdFull.spotLTP ? "top" : "bottom"
    },
    setNearestStrikeBig(state, spotLTP) {
        state.nearestStrikeBig = findNearest(state.ocdBig, spotLTP)
        state.spotPositionBig = state.nearestStrikeBig > state.ocdFullBig.spotLTP ? "top" : "bottom"
    },
    setCallFromOutside(state, payload) {
        state.isCallFromOut = payload
    },
    setCallFromOutsideBig(state, payload) {
        state.isCallFromOutBig = payload
    },
    setInsExp(state, payload) {
        state.insExp = payload
    },
    setInsExpBig(state, payload) {
        state.insExpBig = payload
    },
    setIsOcLoader(state, payload) {
        state.isOcLoader = payload
    },
    setActive_OC_exch(state, payload){
        state.active_OC_exch = payload
    },
    setExchList(state, payload){
        state.exchangeList = payload
    },
    reset_state(state) {
        Object.assign(state,  {
            ocd: '',
            ocdFull: '',
            underlyingList: [],
            underlyingExpiryList: [],
            interval: 10,
            instrument: '',
            expiry: '',
            nearestStrike: '',
            sco: 0,
            bco: 0,
            spo: 0,
            bpo: 0,
            spotPosition: 'top',
            isCallFromOut: false,
            insExp: '',
            ocdBig: '',
            ocdFullBig: '',
            underlyingListBig: [],
            underlyingExpiryListBig: [],
            intervalBig: 10,
            instrumentBig: '',
            expiryBig: '',
            nearestStrikeBig: '',
            scoBig: 0,
            bcoBig: 0,
            spoBig: 0,
            bpoBig: 0,
            spotPositionBig: 'top',
            isCallFromOutBig: false,
            insExpBig: '',
            isOcLoader: false
        });
    },

    resetMiniOc(state) { 
        state.sco = 0
        state.bco = 0
        state.spo = 0
        state.bpo = 0
    },

    resetOc(state) {
        state.scoBig = 0
        state.bcoBig = 0
        state.spoBig = 0
        state.bpoBig = 0 
    },
};

const getters = {
    OCD: state => state.ocd,
    OCDFull: state => state.ocdFull,
    nearestStrike: state => state.nearestStrike,
    underlyingList: state => state.underlyingList,
    expiryList: state => state.underlyingExpiryList,
    instrument: state => state.instrument,
    expiry: state => state.expiry,
    sco: state => state.sco,
    bco: state => state.bco,
    spo: state => state.spo,
    bpo: state => state.bpo,
    spotPosition: state => state.spotPosition,
    isCallFromOut: state => state.isCallFromOut,
    insExp: state => state.insExp,

    // 
    OCDBig: state => state.ocdBig,
    OCDFullBig: state => state.ocdFullBig,
    nearestStrikeBig: state => state.nearestStrikeBig,
    underlyingListBig: state => state.underlyingListBig,
    expiryListBig: state => state.underlyingExpiryListBig,
    instrumentBig: state => state.instrumentBig,
    expiryBig: state => state.expiryBig,
    scoBig: state => state.scoBig,
    bcoBig: state => state.bcoBig,
    spoBig: state => state.spoBig,
    bpoBig: state => state.bpoBig,
    spotPositionBig: state => state.spotPositionBig,
    isCallFromOutBig: state => state.isCallFromOutBig,
    insExpBig: state => state.insExpBig,
    getIsOcLoader: state => state.isOcLoader,
    getExchangeList: state => state.exchangeList
};

const optionChain = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default optionChain