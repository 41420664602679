import { commonService } from '../../../_services/common.service';
import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification()
import errHandle from "../../../handleError/errorHandling"
const state = {
  loader: false
};

const actions = {
  async submitFeedback({ state, commit }, payload) {
    try {
      commit("setIsLoader", true);
      let resp = await commonService.submitFeedback(payload);
      if (resp.status == 200 && resp.data.status == "Ok") {
        notify({ group: 'auth', type: 'success', title: `Feedback submitted` })
      } else if (resp.data.status == "Not ok") {
        notify({ group: 'auth', type: 'error', title: `${resp.data.message}` })
      }
    } catch (error) {
      commit("setIsLoader", false);
      errHandle.statusCodeCheck(error)
    }
    finally {
      commit("setIsLoader", false);
    }
  },
};

const mutations = {
  setIsLoader(state, payload) {
    state.loader = payload;
  },
  reset_state(state) {
    Object.assign(state, {
      loader: false
    });
  },
};

const getters = {
  getLoader: (state) => state.loader
};

const feedback = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export default feedback;
