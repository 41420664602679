import { commonService } from '../../../_services/common.service';
import errHandle from '../../../handleError/errorHandling'
import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification()

const state = {
    isIPODetails:false,
    fields:[
        { qty: 1, rate: 0 , cuttOffFlag: true, bidId:'', orderNo:'', actionCode:'n' }
    ],
    masterIPOList:[],
    announcedList:[],
    recentList:[],
    ipoDetails:null,
    ipoLoader:false
}

const actions = {
    async getMasterIPOList({ state, commit, dispatch, rootGetters }, payload) {
        commit('setMasterIPOList', [])
        commit('setIPOLoader', true)
        try {
            let resp = await commonService.getIPOMaster()
            // resp.data = {
            //     "status": "Ok",
            //     "message": "Success",
            //     "result": [
            //         {
            //             "symbol": "GOPALSNACK",
            //             "name": "Gopal Snacks Limited",
            //             "issueType": "EQUITY",
            //             "lotSize": 37,
            //             "minBidQuantity": 37,
            //             "faceValue": 1.0,
            //             "minPrice": 381.0,
            //             "maxPrice": 401.0,
            //             "tickSize": 1.0,
            //             "cutOffPrice": 401.0,
            //             "biddingStartDate": "06-03-2024",
            //             "biddingEndDate": "11-03-2024",
            //             "isin": "INE0L9R01028",
            //             "issueSize": 11979993,
            //             "registrar": "LINK",
            //             "subType": "",
            //             "categoryDetails": [
            //                 {
            //                     "code": "RETAIL",
            //                     "startTime": "10:00:00",
            //                     "endTime": "17:00:00"
            //                 },
            //                 {
            //                     "code": "EMPRET",
            //                     "startTime": "10:00:00",
            //                     "endTime": "17:00:00"
            //                 }
            //             ],
            //             "subCategorySettings": [
            //                 {
            //                     "caCode": "RETAIL",
            //                     "subCatCode": "IND",
            //                     "minValue": 0.0,
            //                     "maxValue": 200000.0,
            //                     "allowCutOff": true,
            //                     "allowUpi": true,
            //                     "discountType": "",
            //                     "maxUpiLimit": 200000.0
            //                 },
            //                 {
            //                     "caCode": "EMPRET",
            //                     "subCatCode": "EMP",
            //                     "minValue": 0.0,
            //                     "maxValue": 500000.0,
            //                     "allowCutOff": true,
            //                     "allowUpi": true,
            //                     "discountType": "A",
            //                     "maxUpiLimit": 500000.0
            //                 }
            //             ]
            //         },
            //         {
            //             "symbol": "JGCHEM",
            //             "name": "J.G.Chemicals Limited",
            //             "issueType": "EQUITY",
            //             "lotSize": 67,
            //             "minBidQuantity": 67,
            //             "faceValue": 10.0,
            //             "minPrice": 210.0,
            //             "maxPrice": 221.0,
            //             "tickSize": 1.0,
            //             "cutOffPrice": 221.0,
            //             "biddingStartDate": "05-03-2024",
            //             "biddingEndDate": "07-03-2024",
            //             "isin": "INE0MB501011",
            //             "issueSize": 8168714,
            //             "registrar": "KARVY",
            //             "subType": "",
            //             "categoryDetails": [
            //                 {
            //                     "code": "RETAIL",
            //                     "startTime": "10:00:00",
            //                     "endTime": "17:00:00"
            //                 }
            //             ],
            //             "subCategorySettings": [
            //                 {
            //                     "caCode": "RETAIL",
            //                     "subCatCode": "IND",
            //                     "minValue": 0.0,
            //                     "maxValue": 200000.0,
            //                     "allowCutOff": true,
            //                     "allowUpi": true,
            //                     "discountType": "",
            //                     "maxUpiLimit": 200000.0
            //                 }
            //             ]
            //         },
            //         {
            //             "symbol": "PRATHAM",
            //             "name": "Pratham EPC Projects Limited",
            //             "issueType": "EQUITY",
            //             "lotSize": 1600,
            //             "minBidQuantity": 1600,
            //             "faceValue": 10.0,
            //             "minPrice": 71.0,
            //             "maxPrice": 75.0,
            //             "tickSize": 1.0,
            //             "cutOffPrice": 75.0,
            //             "biddingStartDate": "11-03-2024",
            //             "biddingEndDate": "13-03-2024",
            //             "isin": "INE0QA601016",
            //             "issueSize": 4800000,
            //             "registrar": "LINK",
            //             "subType": "SME",
            //             "categoryDetails": [
            //                 {
            //                     "code": "RETAIL",
            //                     "startTime": "10:00:00",
            //                     "endTime": "17:00:00"
            //                 }
            //             ],
            //             "subCategorySettings": [
            //                 {
            //                     "caCode": "RETAIL",
            //                     "subCatCode": "IND",
            //                     "minValue": 0.0,
            //                     "maxValue": 200000.0,
            //                     "allowCutOff": true,
            //                     "allowUpi": true,
            //                     "discountType": "",
            //                     "maxUpiLimit": 200000.0
            //                 }
            //             ]
            //         },
            //         {
            //             "symbol": "SIGNORIA",
            //             "name": "Signoria Creation Limited",
            //             "issueType": "EQUITY",
            //             "lotSize": 2000,
            //             "minBidQuantity": 2000,
            //             "faceValue": 10.0,
            //             "minPrice": 61.0,
            //             "maxPrice": 65.0,
            //             "tickSize": 1.0,
            //             "cutOffPrice": 65.0,
            //             "biddingStartDate": "12-03-2024",
            //             "biddingEndDate": "14-03-2024",
            //             "isin": "INE0RDE01010",
            //             "issueSize": 1428000,
            //             "registrar": "BIGSHARE",
            //             "subType": "SME",
            //             "categoryDetails": [
            //                 {
            //                     "code": "RETAIL",
            //                     "startTime": "10:00:00",
            //                     "endTime": "17:00:00"
            //                 },
            //                 {
            //                     "code": "EMPRET",
            //                     "startTime": "10:00:00",
            //                     "endTime": "17:00:00"
            //                 }
            //             ],
            //             "subCategorySettings": [
            //                 {
            //                     "caCode": "RETAIL",
            //                     "subCatCode": "IND",
            //                     "minValue": 0.0,
            //                     "maxValue": 200000.0,
            //                     "allowCutOff": true,
            //                     "allowUpi": true,
            //                     "discountType": "",
            //                     "maxUpiLimit": 200000.0
            //                 },
            //                 {
            //                     "caCode": "EMPRET",
            //                     "subCatCode": "EMP",
            //                     "minValue": 0.0,
            //                     "maxValue": 500000.0,
            //                     "allowCutOff": true,
            //                     "allowUpi": true,
            //                     "discountType": "",
            //                     "maxUpiLimit": 500000.0
            //                 }
            //             ]
            //         },
            //         {
            //             "symbol": "SKFL",
            //             "name": "Shree Karni Fabcom Limited",
            //             "issueType": "EQUITY",
            //             "lotSize": 600,
            //             "minBidQuantity": 600,
            //             "faceValue": 10.0,
            //             "minPrice": 220.0,
            //             "maxPrice": 227.0,
            //             "tickSize": 1.0,
            //             "cutOffPrice": 227.0,
            //             "biddingStartDate": "06-03-2024",
            //             "biddingEndDate": "11-03-2024",
            //             "isin": "INE0S4Y01010",
            //             "issueSize": 1339200,
            //             "registrar": "MAS",
            //             "subType": "SME",
            //             "categoryDetails": [
            //                 {
            //                     "code": "RETAIL",
            //                     "startTime": "10:00:00",
            //                     "endTime": "17:00:00"
            //                 }
            //             ],
            //             "subCategorySettings": [
            //                 {
            //                     "caCode": "RETAIL",
            //                     "subCatCode": "IND",
            //                     "minValue": 0.0,
            //                     "maxValue": 200000.0,
            //                     "allowCutOff": true,
            //                     "allowUpi": true,
            //                     "discountType": "",
            //                     "maxUpiLimit": 200000.0
            //                 }
            //             ]
            //         },
            //         {
            //             "symbol": "SONAMAC",
            //             "name": "SONA MACHINERY LIMITED",
            //             "issueType": "EQUITY",
            //             "lotSize": 1000,
            //             "minBidQuantity": 1000,
            //             "faceValue": 10.0,
            //             "minPrice": 136.0,
            //             "maxPrice": 143.0,
            //             "tickSize": 1.0,
            //             "cutOffPrice": 143.0,
            //             "biddingStartDate": "05-03-2024",
            //             "biddingEndDate": "07-03-2024",
            //             "isin": "INE0Q6H01012",
            //             "issueSize": 2592000,
            //             "registrar": "MSPL",
            //             "subType": "SME",
            //             "categoryDetails": [
            //                 {
            //                     "code": "RETAIL",
            //                     "startTime": "10:00:00",
            //                     "endTime": "17:00:00"
            //                 }
            //             ],
            //             "subCategorySettings": [
            //                 {
            //                     "caCode": "RETAIL",
            //                     "subCatCode": "IND",
            //                     "minValue": 0.0,
            //                     "maxValue": 200000.0,
            //                     "allowCutOff": true,
            //                     "allowUpi": true,
            //                     "discountType": "",
            //                     "maxUpiLimit": 200000.0
            //                 }
            //             ]
            //         }
            //     ]
            // }
            if ( resp.data.status == "Ok" && resp.data.result) {
                commit('setMasterIPOList', resp.data.result)
            } else {
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        finally {
            commit('setIPOLoader', false)
        }
    },

    // announced List
    async getAnnouncedList({ state, commit, dispatch, rootGetters }, payload) {
        commit('setAnnouncedList', [])
        commit('setIPOLoader', true)
        try {
            let resp = await commonService.getAnnouncedList()
            
            if ( resp.data.status == "Ok" && resp.data.result) {
                commit('setAnnouncedList', resp.data.result)
            } else {
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        finally {
            commit('setIPOLoader', false)
        }
    },

    async getRecentList({ state, commit, dispatch, rootGetters }, payload) {
        commit('setRecentList', [])
        commit('setIPOLoader', true)
        try {
            let resp = await commonService.getRecentList()
            
            if ( resp.data.status == "Ok" && resp.data.result) {
                commit('setRecentList', resp.data.result)
            } else {
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        finally {
            commit('setIPOLoader', false)
        }
    },
};

const mutations = {
    
    setIsIPODetails(state, payload){
        state.isIPODetails = payload
    },
    setIPOLoader(state, payload){
        state.ipoLoader = payload
    },
    setIPODetails(state, payload){
        state.ipoDetails = payload        
    },
    setDefaultQty(state, payload){
        state.defaultQty = payload
    },  
    setMasterIPOList(state, payload){
        state.masterIPOList = payload
    },
    setAnnouncedList(state, payload){
        state.announcedList = payload
    },
    setRecentList(state, payload){
        state.recentList = payload
    },
};

const getters = {
    getIPODetails: state => state.ipoDetails,
    getIPOMasterList: state => state.masterIPOList,
    getIPOLoader: state => state.ipoLoader,
    getAnnouncedList: state => state.announcedList
};

const ipo = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default ipo