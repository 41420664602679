import _store from "../_store/index";
export function authHeader() {
    let accessToken = _store.state.login.accessToken
    if (accessToken) {
        return { 'Authorization': 'Bearer ' + accessToken };
    } else {
        return {};
    }
}

export function loginAuthHead() {
   
    let token = _store.state.login.authToken
    let ucc = _store.state.login.UCC
    if (token && ucc) {
        return { 'Authorization': `Bearer ${ucc} WEB ${token}` };
    } else {
        return {};
    }
}

// export function authHeaderWs() {
//     let accessToken = _store.state.login.accessToken
//     let userId = JSON.parse(localStorage.getItem('userId'))
//     if (accessToken) {
//         return { 'Authorization': 'Bearer ' + userId + ' ' + accessToken };
//     } else {
//         return {};
//     }
// }