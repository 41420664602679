export const endpoint = {
    // EKYC ENDPOINTS   
    sendMobileOtp : 'user/sendSmsOtp',
    verifyMobileOtp : 'user/verifySmsOtp',
    sendEmailOtp: 'user/sendMailOtp',
    verifyEmailOtp: 'user/verifyEmailOtp',
    createPassword: 'user/creation',

    // SSO API ENDPOINTS
    getVendorDetails: 'sso/vendor/deatils',
    checkVendorAuthorize: 'sso/vendor/authorize/check',
    vendorAuthorize: 'sso/vendor/authorize',

    // WEB LOGIN API ENDPOINTS   
    verifyClient : 'access/client/verify',
    validatePassword : 'access/pwd/validate',
    sendWebAuthOtp : 'access/otp/send',
    verifyWebAuthOtp: 'access/otp/validate',
    forgotPass: 'access/pwd/forget',
    forgotPassVerify : 'access/pwd/forget/verify',
    unblockAcc: 'access/client/unblock',
    unblockAccVerify: 'access/client/unblock/verify',
    resetPassword: 'access/pwd/reset',
    registerTotp: 'access/scanner/generate',
    getScannerData: 'access/scanner/get',
    enableTotp: 'access/topt/enable',
    verifyTotp: 'access/topt/verify',
    changePassword: 'access/pwd/change',
    getQRSession: 'access/qr/login',

    // WEB OTHER API ENDPOINTS   
    getUserPref: 'preferences/web',
    updateUserPref: 'preferences/web/update',
    getProfileData : 'profile/getclientdetails',
    getTickerTapeList: 'ticker-tape/get',
    getSecurityInfo: 'scrip/security/info',
    
}