import { commonService } from '../../../_services/common.service';
import errHandle from '../../../handleError/errorHandling'
import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification()
const state = {
    basketList: [],
    basketScrips: [],
    currentBasketData: '',
    loader: false,
    searchLoader: false,
    isBasket: false,
    basketMargin: '',
    basketOwType: '',
    currentScripData: ''
}

const actions = {
    async getBasketList({ state, commit, dispatch, rootGetters }, payload) {
        try {
            if(payload?.from != 'close') {
                commit('setLoader', true, { root: true })
            }
            let json = {
                "userId": rootGetters['login/getUCC'],
            }
            let resp = await commonService.getBasketData(json)
            if (resp.data.status == "Ok" && resp.data.message == "Success") {
                dispatch('setBasketList', resp.data.result)
                if(payload?.from == 'close') {
                    let index = resp.data.result.findIndex(x => x.basketId == payload.data.basketId )
                    let currentData = resp.data.result[index]
                    commit('setCurrentBasketData', currentData)
                }
                
            } else {
                dispatch('setBasketList', [])
            }
        } catch (error) {
            commit('setLoader', false, { root: true })
            errHandle.statusCodeCheck(error)
        }
        commit('setLoader', false, { root: true })
    },

    async createBasket({ state, commit, dispatch, rootGetters }, payload) {
        try {
            let json = {
                "basketName": payload.basketName,
            }
            let resp = await commonService.createBasket(json)
            if (resp.data.status == "Ok" && resp.data.message == "Success") {
                notify({ group: 'auth', type: 'success', title: `Basket created` })
                dispatch('getBasketList')
                commit('setCreateBaskettDialog', false, { root: true })
            } else if(resp.data.message != "Success") {
                notify({ group: 'auth', type: 'error', title: `${resp.data.message}` })
            }   
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
    },

    async renameBasket({ state, commit, dispatch, rootGetters }, payload) {
        try {
            let json = {
                "basketId" : payload.basketId,
                "basketName" : payload.basketName
            }
            let resp = await commonService.renameBasket(json)
            if (resp.data.status == "Ok" && resp.data.message == "Success") {
                notify({ group: 'auth', type: 'success', title: `Basket Renamed` })
            } 
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
    },

    async deleteBasket({ state, commit, dispatch, rootGetters }, payload) {
        try {
            let resp = await commonService.deleteBasket(payload.id)
            if (resp.data.status == "Ok" && resp.data.message == "Success") {
                notify({ group: 'auth', type: 'success', title: `Basket deleted` })
                dispatch('setBasketList', resp.data.result)
                commit('setDeleteScripDialog', false, { root: true })
                // dispatch('getBasketList')
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
    },

    async getBasketScrips({ state, commit, dispatch, rootGetters }, payload) {
        try {
            commit('setLoader',true)
            let resp = await commonService.getBasketScrips(payload.basketId)
            if (resp.data.status == "Ok" && resp.data.message == "Success") {
                commit('setBasketScrips', resp.data?.result)
            } else {
                commit('setBasketScrips', [])
            }
            dispatch('getBasketMargin')
        } catch (error) {
            commit('setLoader',false)
            errHandle.statusCodeCheck(error)
        }
        commit('setLoader',false)
    },

    async addScripInBasket({ state, commit, dispatch, rootGetters }, payload) {
        try {
            let data = payload[0]
            let json = { "basketId": state.currentBasketData.basketId }
            let json1 = {
                "exchange": data.exchange,
                "token": data.token,
                "tradingSymbol": data.tradingSymbol,
                "qty": data.qty,
                "price": data.price,
                "product": data.product,
                "transType": data.transType,
                "priceType": data.priceType,
                "orderType": data.orderType,
                "ret": data.ret,
                "triggerPrice": data.triggerPrice,
                "disclosedQty": data.disclosedQty,
                "MktProtection": data.MktProtection,
                "target": data.target,
                "stopLoss": data.stopLoss,
                "trailingStopLoss": data.trailingPrice,
            }
            json.scrips = json1
            let resp = await commonService.addScripInBasket(json)
            if (resp.data.status == "Ok" && resp.data.message == "Success") {
                notify({ group: 'auth', type: 'success', title: `Scrip Added` })
                dispatch('unSubsBasket')
                commit('setBasketScrips', resp.data?.result)
                dispatch('getBasketMargin')
            } else {
                notify({ group: 'auth', type: 'error', title: `${resp.data.message}` })
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
    },

    async deleteScripInBasket({ state, commit, dispatch, rootGetters }, payload) {
        try {
            let json = {
                "basketId": payload.basketId,
                "scripsId": [payload.id]
            }
            let resp = await commonService.deleteScripInBasket(json)
            if (resp.data.status == "Ok" && resp.data.message == "Success") {
                notify({ group: 'auth', type: 'success', title: `Scrip Deleted` })
                commit('setBasketScrips', resp.data?.result)
                commit('setDeleteScripDialog', false, { root: true })
            } else {
                notify({ group: 'auth', type: 'error', title: `${resp.data.message}` })
            }
            dispatch('getBasketMargin')
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
    },
    
    async setBasketList({ state, dispatch, rootGetters }, payload) {
        state.basketList = payload
        dispatch('order/setOrdersTabsCount', {
            open: rootGetters['order/getOpenOrders'],
            exec: rootGetters['order/getExcutedOrders'], 
            basket: state.basketList,
            tradeBook : rootGetters['order/getTradeBook'],
            positions: rootGetters['positions/getPositionsData'],
        }, { root: true })
    },

    async subsBasket({ state, rootGetters, dispatch }, payload) {
        if (rootGetters['ws/getConnectionStatus']) {
            let channel = ''
                state.basketScrips.forEach(el => {
                    channel += `${el['exchange']}|${el['token']}#`
                });
            var tempData = {
                sub: channel,
                stage: 'initial'
            }
            dispatch("ws/basketSubscription", tempData, { root: true })
        }
    },

    async unSubsBasket({ state, rootGetters, dispatch }) {
        if (rootGetters['ws/getConnectionStatus']) {
            var tempData = {
                sub: rootGetters['ws/getBasketSubs'],
                stage: 'esc'
            }
            dispatch("ws/basketSubscription", tempData, { root: true })
        }
    },

    async getBasketMargin({ state,commit, rootGetters, dispatch }) {
        try {
            let array = []
            state.basketScrips.forEach(el => {
                let json = {
                    "exchange": el.exchange,
                    "price": el.price,
                    "qty": el.qty,
                    "token": el.token,
                    "transType": el.transType,
                    "priceType": el.priceType,
                    "product" : el.product,
                    "tradingSymbol": el.tradingSymbol,
                    "triggerPrice" :  el.priceType == "SL" || el.priceType == "SL-M" ? el.triggerPrice : undefined
                }
                array.push(json)
            });
            let resp = await commonService.getBasketMargin(array)
            if (resp.data.status == "Ok" && resp.data.message == "Success" && resp.data.result.length) {
                commit('setBasketMargin', resp.data.result[0] )
            } else {
                commit('setBasketMargin', 0 )
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
    },

    async executeBasket({state, commit, dispatch}, payload) {
        try {
            let json = {
                "basketId": payload.basketId,
                "scrips": []
            }
            state.basketScrips.forEach(el => {
                let j = {
                    "exchange": el.exchange,
                    "token": el.token,
                    "tradingSymbol": el.tradingSymbol,
                    "qty": el.qty,
                    "price": el.price,
                    "product":  el.product,
                    "transType": el.transType,
                    "priceType": el.priceType,
                    "orderType": el.orderType,
                    "ret": el.ret,
                    "triggerPrice": el.triggerPrice,
                    "disclosedQty": el.disclosedQty,
                    "mktProtection": el.mktProtection,
                    "target": el.target,
                    "stopLoss": el.stopLoss,
                    "trailingStopLoss": el.trailingStopLoss,
                    "source":"WEB",
                    
                }
                json.scrips.push(j)
            });
            let resp = await commonService.executeBasket(json)
            if (resp.status == 200 && resp.data[0].status == 'Ok' && resp.data[0].message == 'Basket exeuted successfully') {
                dispatch('getBasketList', { from: 'close', data: payload })
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }

    },

    async resetBasketExeStatus({state, commit, dispatch},basketId) {
        try {
            let resp = await commonService.resetBasketExeStatus(basketId)
            if (resp.data.status == "Ok" && resp.data.message == "Success") {
                commit('setCurrentBasketData', resp.data.result[0])
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
    },

    async editScripInBasket({ state, commit, dispatch, rootGetters }, payload) {
        try {
            let data = payload[0]
            let json = { "basketId": state.currentBasketData.basketId }
            let json1 = {
                "exchange": data.exchange,
                "token": data.token,
                "tradingSymbol": data.tradingSymbol,
                "qty": data.qty,
                "price": data.price,
                "product": data.product,
                "transType": data.transType,
                "priceType": data.priceType,
                "orderType": data.orderType,
                "ret": data.ret,
                "triggerPrice": data.triggerPrice,
                "disclosedQty": data.disclosedQty,
                "MktProtection": data.MktProtection,
                "target": data.target,
                "stopLoss": data.stopLoss,
                "trailingStopLoss": data.trailingPrice,
                "lotSize" : data.lotSize,
                "id" : data.id,
                "source": "WEB",
            }
            json.scrips = json1
            let resp = await commonService.editBasketScrip(json)
            if (resp.data.status == "Ok" && resp.data.message == "Success") {
                notify({ group: 'auth', type: 'success', title: `Scrip Modified` })
                dispatch('unSubsBasket')
                dispatch('getBasketMargin')
                dispatch('basket/getBasketScrips', rootGetters['basket/getcurrentBasketData'], { root:true })
            } else {
                notify({ group: 'auth', type: 'error', title: `${resp.data.message}` })
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
    },
};

const mutations = {
    setBasketScrips(state, payload) {
        state.basketScrips = payload
        this.dispatch('basket/subsBasket')
    },
    setCurrentBasketData(state, payload) {
        state.currentBasketData = payload
    },
    setLoader(state, payload) {
        state.loader = payload
    },
    setSearchLoader(state, payload) {
        state.searchLoader = payload
    },
    setIsBasket(state, payload) {
        state.isBasket = payload
    },
    setBasketMargin(state, payload) {
        state.basketMargin = payload
    },
    setBasketOwType(state, payload) {
        state.basketOwType = payload
    },
    reset_state(state) {
        Object.assign(state, {
            basketList: [],
            basketScrips: [],
            currentBasketData: '',
            loader: false,
            searchLoader: false,
            isBasket: false,
            basketMargin: '',
            basketOwType: '',
            currentScripData: ''
        });
    },
    setCurrentScripData(state, payload) {
        state.currentScripData = payload
    }
};

const getters = {
    getBasketList: state => state.basketList,
    getcurrentBasketData: state => state.currentBasketData,
    getBasketScrips: state => state.basketScrips,
    getLoader: state => state.loader,
    getSLoader: state => state.searchLoader,
    getBasketMargin : state => state.basketMargin,
    getBasketOwType : state => state.basketOwType,
    getIsBasketDialog: state => state.isBasket,
    getCurrentScripData: state => state.currentScripData
};

const basket = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default basket