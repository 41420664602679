const chart = {
  namespaced: true,
  state:{
    chartCancelDialog: false,
    cancelArray:'',
    refreshArray:'',
    popOutRefresh:'',
    currentChartData:'',
    tradeChartData:'',
    chartIndicators: [],
    chartUrl:''
  },
  mutations:{
    setChartCancel(state, payload){
        state.chartCancelDialog = payload
    },
    setCancelArray(state, payload){
        state.cancelArray = payload
    },
    setRefreshArray(state, payload){
        state.refreshArray = payload
    },
    setPopOutRefresh(state, payload){
      state.popOutRefresh = payload
    },
    setChartData(state , payload){
        state.currentChartData = payload;
        localStorage.setItem('chartData' , JSON.stringify(payload))
      },
    setTardeData(state, payload) {
      state.tradeChartData = payload
    },
    setChartIndicators(state, payload){
      state.chartIndicators = payload
      localStorage.setItem('chartIndicators' ,JSON.stringify(payload))
    },
    setChartUrl(state, payload){
      state.chartUrl = payload
    },
    reset_state(state) {
      Object.assign(state, {
        chartCancelDialog: false,
        cancelArray:'',
        refreshArray:'',
        popOutRefresh:'',
        currentChartData:'',
        tradeChartData:'',
        chartIndicators: [],
      });
    },
  },
  getters:{
    getIframeId: state => state.refreshArray,
    getPopIframeId : state => state.popOutRefresh,
    getTradeChartData : state => state.tradeChartData
  }
}
export default chart