import { commonService } from '../../../_services/common.service';
import errHandle from '../../../handleError/errorHandling'
import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification()
const state = {
   gttList:[]
}

const actions = {
    
    // get GTT Orders
    async getGTTOrders({ state, commit, dispatch, rootGetters }, payload) {
        commit('setLoader', true, { root: true })
        commit('setGTTList', [])
        commonService.getGTTOrders().then((resp)=>{
            if (resp.status == 200 && resp.data.status == "Ok") {
                commit('setGTTList', resp.data?.result)
            }
        },(error) =>{
            errHandle.statusCodeCheck(error)
        }).finally(()=>{
            commit('setLoader', false, { root: true })
        })
    },
    
};

const mutations = {
    setGTTList(state, payload){
        state.gttList = payload
    }
};

const getters = {
    getGTTList: (state)=> state.gttList
};

const gtt = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default gtt