import { mapGetters } from "vuex"
import store from '../_store/index'
function checkNullNan(value) {
    return value && !!value && value.toString() != "NA" && value.toString() != "-" && !isNaN(value) ? true : false
}
function realisedProfitLoss(data, key) {
    let buyAvgKey = key == 'mtm' ? 'mtmBuyPrice' : 'buyPrice'
    let sellAvgKey = key == 'mtm' ? 'mtmSellprice' : 'sellPrice'
    let netBuyQty = 'buyQty'
    let netSellQty = 'sellQty'
    var realise = 0
    var closedQty = ''
    var tempQty = 0
    
    if (parseFloat(data[netBuyQty]) > 0 && parseFloat(data[netSellQty]) > 0) {
        if (parseFloat(data[netBuyQty]) > parseFloat(data[netSellQty])) {
            closedQty = parseFloat(data[netSellQty])
        } else {
            closedQty = parseFloat(data[netBuyQty])
        }
        closedQty = checkNullNan(closedQty) ? closedQty : 0
        if (data.exchange == "NFO" || data.exchange == "BFO" || data.exchange == "NSE" || data.exchange == "BSE") {
            tempQty = closedQty
        } else if (data.exchange == "MCX" && data?.pnlLotsize) {
            tempQty = closedQty * parseFloat(data['pnlLotsize'])
        } else {
            tempQty = closedQty * parseFloat(data['lotsize'])
        }
        realise = tempQty * (parseFloat(data[sellAvgKey]) - parseFloat(data[buyAvgKey]));
        realise = data.exchange == "CDS" ? realise * 1000 : realise
        data['realise'] = realise
        return realise
    } else {
        return realise
    }
}

function unRealisedProfitLoss(data, key) {
    let buyAvgKey = key == 'mtm' ? 'mtmBuyPrice' : 'buyPrice'
    let sellAvgKey = key == 'mtm' ? 'mtmSellprice' : 'sellPrice'
    var unRealise = 0
    let netQty = Number(data['netQty'])
    if (parseFloat(netQty) != 0) {
        var tempAvgPrc = parseFloat(netQty) > 0 ? parseFloat(data["ltp"]) - parseFloat(data[buyAvgKey]) : parseFloat(data[sellAvgKey]) - parseFloat(data["ltp"])
        var tempQtyMul = 0
        if(data.exchange == "NFO" || data.exchange == "BFO" ||  data.exchange == "NSE" ||  data.exchange == "BSE"){
            tempQtyMul = parseFloat(netQty)
        } else if(data.exchange == "MCX" && data?.pnlLotsize){
            tempQtyMul = parseFloat(netQty) * parseFloat(data['pnlLotsize'])
        } else{
            tempQtyMul = parseFloat(netQty) * parseFloat(data['lotsize'])
        }
        if(tempQtyMul < 0){
            tempQtyMul = tempQtyMul * -1;
        }
        unRealise = tempQtyMul * tempAvgPrc
        unRealise = data.exchange == "CDS" ? unRealise * 1000 : unRealise
        data['realise'] = unRealise
        return unRealise
    } else {
        return unRealise
    }
}

async function holdingsProfitLoss(key) {
    store.state.holdings.totalInvestment = 0,
    store.state.holdings.totalcurrentValue = 0,
    store.state.holdings.totalPnl = 0
    store.state.holdings.totalPnlChange = 0
    store.state.holdings.daysPnl = 0
    store.state.holdings.daysPnlChange = 0
    store.state.holdings.totalPdc = 0
    var obj = {}
    var currecntTab = []
    var groupPnl = []
    var groupInvest = []
    var groupColor = []
    let totalPreviousDayClose = 0
    let rgbObj = {}
    await store.state.holdings?.holdingsData?.forEach((data,idx) => {
        let sellableQty = parseFloat(data["sellableQty"])
        data.exchange = data.exchange
        data.token = data.token
        data["btstQty"] = data["btstQty"] ? data["btstQty"] : 0
        data["sellableQty"] = data["sellableQty"] ? data["sellableQty"] : 0
        data["buyPrice"] = data["buyPrice"] ? data["buyPrice"] : 0
        data["netPnl"] = netPnlCalc(data);
        data["daysPnl"] = netDaysPnlCalc(data);
        // calculate buy value
        data["buyValue"] = parseFloat(Number(data["buyPrice"]) * Number(data['netQty'])).toFixed(2)
        // calculate buy value
        let buyAvgHold = parseFloat(data["buyPrice"]);
        let pdc = data['pdc']
        let ltp = data['ltp']
        if (buyAvgHold !== 0) {
            data["netChg"] = netChgCalc(data)
            data["dayChg"] = dayChgCalc(data)
        } else {
            data["netChg"] = 0;
            data["dayChg"] = 0;
        }

        // Starting of holdings tree chart data build //
          data.rgb = !store.state.holdings.rgbObj[data.token] ? getRandomRgbColor() : store.state.holdings.rgbObj[data.token]
          rgbObj[data.token] = data.rgb
          let current = { value: Math.abs(parseFloat(ltp) * Number(data['netQty'])), name: data.tradingSymbol, color: data?.rgb , value1: parseFloat(Number(ltp) * Number(data['netQty']))?.toFixed(2) }
          let invest = { value: Math.abs(data["buyValue"]), name: data.tradingSymbol, color: data?.rgb, value1: parseFloat(data["buyValue"])?.toFixed(2) }
          let pnl = { value:  Math.abs(data["netPnl"]), name: data.tradingSymbol, color: Number(data["netPnl"]) >= 0 ? 'rgb(64,177,68)' : 'rgb(227,66,33)' , value1: parseFloat(data["netPnl"])?.toFixed(2) }
          currecntTab.push(current),
          groupPnl.push(pnl),
          groupInvest.push(invest)
        //   groupColor.push(getRandomColor())

        // End of holdings tree chart data build //

        // total investment Calculation
        store.state.holdings.totalInvestment += Number(data["buyValue"])

        // total currentValue Calculation
        store.state.holdings.totalcurrentValue += parseFloat(ltp) * sellableQty;

        // total p&l calculation
        store.state.holdings.totalPnl = store.state.holdings.totalPnl + parseFloat(data["netPnl"])

        // total p&l change calculation
        store.state.holdings.totalPnlChange = store.state.holdings.totalInvestment == 0 ? 0 : ((store.state.holdings.totalcurrentValue - store.state.holdings.totalInvestment) / store.state.holdings.totalInvestment) * 100
        store.state.holdings.totalPnlChange = store.state.holdings.totalPnlChange == 'Infinity' ? 'NA' : store.state.holdings.totalPnlChange

        
        // find sum of Day's P&L
        if (pdc) {
            store.state.holdings.daysPnl += (parseFloat(ltp) - parseFloat(pdc)) * sellableQty;
            // if(data.exDetails[0].symbol == 'IOC-EQ' && key == 'webscoket'){ } 
            totalPreviousDayClose = totalPreviousDayClose + (parseFloat(pdc) * sellableQty);
            store.state.holdings.totalPdc += parseFloat(pdc) * Number(data['netQty'])
            // find Day's P&L Change
            store.state.holdings.daysPnlChange = totalPreviousDayClose == 0 ? 0 : ((store.state.holdings.totalcurrentValue - totalPreviousDayClose) / totalPreviousDayClose) * 100
        }

        obj[`${data.exchange}|${data.token}`] = data
    });

    if (key == 'Intial' && !store.state.holdings.holdingsChartData) {
        let temp = {
            currentValue: currecntTab,
            investmentValue: groupInvest,
            pnl: groupPnl,
            color: groupColor
        }
        store.commit('holdings/setHoldingsChartData', temp)
    }
    store.commit('holdings/setHoldingsData', store.state.holdings.holdingsData, { root: true })
    store.commit('holdings/setObjectHoldingList', obj, { root: true })
    store.commit('holdings/setRgbObj', rgbObj)
}

function netPnlCalc(data) {
    let uploadedPrc = parseFloat(data['buyPrice'])
    // 
    let tradedQty = parseFloat(data['tradedQty'])
    let sellAmount = parseFloat(data['sellAmount'])
    let ltp = parseFloat(data['ltp'])
    let netQty = parseFloat(data['netQty'])
    let netPnl = 0

    let realizedPnl = sellAmount - (uploadedPrc * tradedQty);
    let unrealizedPnl = (netQty - tradedQty) * (ltp - uploadedPrc);
    netPnl = realizedPnl + unrealizedPnl;
    data['netPnl'] = netPnl
    return netPnl;
}

function netDaysPnlCalc(data) {
    let pdc = parseFloat(data['pdc'])
    // 
    let tradedQty = parseFloat(data['tradedQty'])
    let sellAmount = parseFloat(data['sellAmount'])
    let ltp = parseFloat(data['ltp'])
    let netQty = parseFloat(data['netQty'])
    let daysPnl = 0
    
    let realizedPnl = sellAmount - (pdc * tradedQty);
    let unrealizedPnl = (netQty - tradedQty) * (ltp - pdc);
    daysPnl = realizedPnl + unrealizedPnl;
    data['daysPnl'] = daysPnl
    return daysPnl;
}

function netChgCalc(data) {
    // formula ==> ((ltp - buyPrice) / buyPrice)  * 100
    let netChg = 0;
    if(parseFloat(data["buyPrice"]) != 0) {
        netChg = ((parseFloat(data['ltp']) - parseFloat(data["buyPrice"])) / parseFloat(data["buyPrice"])) * 100
        netChg = parseFloat(netChg).toFixed(2)
        data['netChg'] = netChg
    } else {
        netChg = '0.00'
    }
    return netChg;
}

function dayChgCalc(data) {
    // formula ==> ((ltp - pdc) / pdc)  * 100
    let dayChg = '0.00';
    let pdc = data['pdc']
    if(Number(pdc) != 0) {
        dayChg = ((parseFloat(data['ltp']) - parseFloat(pdc)) / pdc) * 100
        dayChg = parseFloat(dayChg).toFixed(2)
        data['dayChg'] = dayChg
        return dayChg;
    } else {
        return dayChg;
    }
}

function totalCurrentValue(array) {
    let sum = 0
    if (array && array?.length != 0) {
        let tradedValue = array?.reduce(function (total, item) {
            let sellAmt = item['sellAmount'] && parseFloat(item['sellAmount']) > 0 ? parseFloat(item['sellAmount']) : 0
            return parseFloat(total) + sellAmt
        }, 0)
        let remainValue = array?.reduce(function (total, item) {
            let sellable = item['sellableQty'] ? item['sellableQty'] : 0
            return parseFloat(total) + parseFloat(item["ltp"]) * parseFloat(sellable)
        }, 0)
        sum = parseFloat(tradedValue) + parseFloat(remainValue)
        sum = sum ? sum : 0
    }
    return (parseFloat(sum).toFixed(2));
}

function totalTodaysPnl(array) {
    if (array.length != 0) {
        let sum = 0
        array.forEach(el => {
            sum += netDaysPnlCalc(el)
        });
        sum = sum ? parseFloat(sum).toFixed(2) : 0
        return sum
    }
}

// find Day's P&L Change
function totalTodaysPnlChg(array) {
    if (array.length != 0) {
        let totalchg = 0
        // let totalPdc = store.state.holdings.totalPdc
        let totalPdc = getTotalPdc();
        totalchg = parseFloat(totalPdc == 0 ? 0 : (Number((totalCurrentValue(array)) - totalPdc) / totalPdc) * 100)
        totalchg = totalchg ? parseFloat(totalchg).toFixed(2) : 0
        return totalchg;
    }
}

// find Net P&L Change
function totalNetPnl(array) {
    if (array?.length != 0) {
        let sum = array?.reduce(function (total, item) {
            let netPnl = netPnlCalc(item)
            return parseFloat(total) + netPnl
        }, 0);
        sum = sum ? parseFloat(sum).toFixed(2) : 0
        return sum;
    }
}

function totalNetPnlChg(array) {
    if (array?.length != 0) {
        let totalchg = store.state.holdings.totalInvestment == 0 ? 0 : ((Number(totalCurrentValue(array)) - store.state.holdings.totalInvestment) / store.state.holdings.totalInvestment) * 100
        totalchg = totalchg == 'Infinity' ? 'NA' : totalchg
        totalchg = totalchg ? parseFloat(totalchg).toFixed(2) : 0
        return totalchg;
    }
}

function getTotalPdc() {
    let totalPdc = 0
    if(store.state.holdings.holdingsData && store.state.holdings.holdingsData.length) {
        store.state.holdings.holdingsData.forEach((data) => {
            if(data['pdc'] && data['netQty']) {
                totalPdc += (Number(data['pdc']) * Number(data['netQty']))
            }
        })
    }
    return totalPdc;
}

function getRandomRgbColor() {
    // const myArray = ['RGB(43, 100, 200)','RGB(55, 115, 195)','RGB(62, 130, 241)','RGB(26, 73, 107)','RGB(84, 150, 229)','RGB(52, 101, 164)','RGB(44, 62, 80)','RGB(41, 128, 185)','RGB(40, 116, 166)','RGB(52, 152, 219)','RGB(31, 58, 147)','RGB(30, 55, 153)','RGB(53, 152, 220)','RGB(41, 128, 186)','RGB(93, 165, 218)','RGB(10, 79, 111)','RGB(44, 62, 80)','RGB(26, 76, 111)','RGB(67, 139, 202)','RGB(0, 114, 198)']
    // rgb(103, 58, 183)
    const myArray = ['rgb(33, 150, 243)','rgb(156, 39, 176)','rgb(71, 114, 243)','rgb(3, 169, 244)','rgb(127 155 236)','rgb(123 52 250)','rgb(63, 81, 181)','rgb(0, 188, 212)','rgb(0, 150, 136)','rgb(76, 175, 80)','rgb(139, 195, 74)','rgb(205, 220, 57)','rgb(255, 235, 59)','rgb(255, 193, 7)','rgb(255, 152, 0)']
    const randomIndex = Math.floor(Math.random() * myArray.length);
    const randomElement = myArray[randomIndex];
    return randomElement;
}
function getChg(ltp, pdc, exchange){
    let chg = Number(ltp) - Number(pdc)
    
    chg ? '' : chg = 0
    return parseFloat(chg).toFixed(exchange == 'CDS' || exchange == 'BCD' ? 4 : 2)
  }

const formula = {
    checkNullNan : checkNullNan,
    realisedProfitLoss:realisedProfitLoss,
    unRealisedProfitLoss:unRealisedProfitLoss,
    holdingsProfitLoss:holdingsProfitLoss,
    netPnlCalc:netPnlCalc,
    netDaysPnlCalc: netDaysPnlCalc,
    netChgCalc:netChgCalc,
    dayChgCalc: dayChgCalc,
    totalCurrentValue:totalCurrentValue,
    totalTodaysPnl:totalTodaysPnl,
    totalTodaysPnlChg:totalTodaysPnlChg,
    totalNetPnl: totalNetPnl,
    totalNetPnlChg:totalNetPnlChg,
    getTotalPdc: getTotalPdc,
    getRandomRgbColor:getRandomRgbColor,
    getChg: getChg
}

export default formula