const state = { currentTabHeader: 'F&O Margin Calculation (SPAN)' }
const actions = {};
const mutations = {
    setCurrentTabHeader(state, tab) {
        state.currentTabHeader = tab
    },
    reset_state(state) {
        Object.assign(state, { currentTabHeader: 'F&O Margin Calculation (SPAN)' });
    },
};
const getters = {
    getCurrentTabHeader: state => state.currentTabHeader
};


const commonTabs = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default commonTabs