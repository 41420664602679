import { commonService } from '../../../_services/common.service';
import errHandle from '../../../handleError/errorHandling'
import {env} from "../../../_helpers/env_index"
import favicon from '../../../assets/img/client-logo.svg'
import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification()
let checkHdfcTimeout;
// const Razorpay = window.Razorpay || null;
const state = {
    fundsData: '',
    fundsTabs: [],
    comFundsData: [],
    isEqLoader: false,
    isComLoader: false,
    isPayin: false,
    isPayout: false,
    isPayinLoader: false,
    isPayoutLoader: false,
    banksList: [],
    segmentList: [],
    isPayinDetailsLoader: false,
    isPayoutDetailsLoader: false,
    filteredSegments: [],
    isHdfcPayment: false,
    hdfcOrderId: '',
    isBoRedirectLoader: false,
    currentPaymentAmount: '',
    razerPayKey: env().razerPayKey
}
const actions = {
    async getFunds({ state, commit, dispatch, rootGetters }, payload) {
        try {
            if(payload == 'funds') {
                commit('setLoader', true, { root: true })
            }
            commit('setEqLoader', true)
            let resp = await commonService.getFundsData()
            if (resp.status == 200 && resp.data.status == "Ok") {
                commit('setFunds', resp.data.result[0])
            } else {
                commit('setFunds', '')
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        finally {
            if(payload == 'funds') {
                commit('setLoader', false, { root: true })
            }
            commit('setEqLoader', false)
        }
    },
    async getComFunds({ state, commit, dispatch, rootGetters }, payload) {
        try {
            if(payload == 'funds') {
                commit('setLoader', true, { root: true })
            }
            commit('setComLoader', true)
            let resp = await commonService.getComFundsData()
            if (resp.status == 200 && resp.data.status == "Ok") {
                commit('setComFunds', resp.data.result[0])
            } else {
                commit('setComFunds', '')
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        finally {
            if(payload == 'funds') {
                commit('setLoader', false, { root: true })
            }
            commit('setComLoader', false)
        }
    },
    async setActiveTab({state,commit},payload) {
        state.fundsTabs.forEach(el => {
            if(el.id == payload) {
                el.active = true
            } else {
                el.active = false
            }
        });
    },
    async payinApiCall({ state, commit, dispatch, rootGetters }, payload) {
        try {
            commit('setIsPayinLoader', true)
            let resp = await commonService.payinApiCall(payload)
            if (resp.status == 200 && resp.data.status == "Ok") {
                window.open(resp.data.message, '_blank')
            } else if (resp.data.status == "Not ok") {
                notify({ group: 'auth', type: 'error', title: `${resp.data.message}` })
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        finally {
            commit('setIsPayinLoader', false)
        }
    },
    async payoutApiCall({ state, commit, dispatch, rootGetters }, payload) {
        try {
            commit('setIsPayoutLoader', true)
            let resp = await commonService.payoutApiCall(payload)
            if (resp.status == 200 && resp.data.status == "Ok") {
            //    window.open(resp.data.message, '_self')
                notify({ group: 'auth', type: 'success', title: `${resp.data.result[0]?.message}` })
            } else if(resp.data.status == "Not ok") {
                notify({ group: 'auth', type: 'info', title: `Your details not updated contact admin` })
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        finally {
            commit('setIsPayoutLoader', false)
        }
    },
    async getUserBankDetails({ state, commit, dispatch, rootGetters }, payload) {
        try {
            if(payload == 'payin') {
                commit('setIsPayinDetailsLoader', true)
            } else if(payload == 'payout') {
                commit('setIsPayoutDetailsLoader', true)
            }
            
            let resp = await commonService.getUserBankDetails()
            if (resp.status == 200 && resp.data.status == "Ok") {
                if (resp.data.result[0].bankDetails.length) {
                    commit('setBanksList', resp.data.result[0].bankDetails)
                } else {
                    commit('setBanksList', [])
                }
                if (resp.data.result[0].exchange.length) {
                    commit('setSegmentList', resp.data.result[0].exchange)
                } else {
                    commit('setSegmentList', [])
                }
            } else {
                notify({ group: 'auth', type: 'info', title: `Your details not updated contact admin` })
            }
        } catch (error) {
            if(payload == 'payin') {
                commit('setIsPayinDetailsLoader', false)
            } else {
                commit('setIsPayoutDetailsLoader', false)
            }
            errHandle.statusCodeCheck(error)
        }
        finally {
            if(payload == 'payin') {
                commit('setIsPayinDetailsLoader', false)
            } else {
                commit('setIsPayoutDetailsLoader', false)
            }
        }
    },
    async callHdfcPayment({ state, commit, dispatch, rootGetters }, payload) {
        try {
            commit('setIsPayinLoader', true)
            let resp = await commonService.callHdfcPayment(payload)
            if (resp.status == 200 && resp.data.message == "Success" && resp.data.orderId) {
                commit('setHdfcOrderId', resp.data.orderId)
                commit('setIsHdfcPaymentLoader', true)
                dispatch('checkHdfcPayment')
            } else if(resp.data.status == "Not ok" ) {
                notify({ group: 'auth', type: 'error', title: `${resp.data.reason}` })
            } else if(resp.data.message == "Failed") {
                notify({ group: 'auth', type: 'error', title: `${resp.data.result[0]}` })
            }
        } catch (error) {
            commit('setIsPayinLoader', false)
            commit('setIsHdfcPaymentLoader', false)
            errHandle.statusCodeCheck(error)
        }
        finally {
            commit('setIsPayinLoader', false)
        }
    },

    async checkHdfcPayment({ state, commit, dispatch, rootGetters }, payload) {
        try {
            let json = {
                "orderNo": state.hdfcOrderId,
                "clientId": rootGetters['login/getUCC']
            }
            let resp = await commonService.checkHdfcPayment(json)
            clearTimeout(checkHdfcTimeout);
            if (resp.data.result == "REJECTED - Transaction fail"
                || resp.data.result == "EXPIRED - Transaction fail" || resp.data.result == "FAILED - Transaction fail") {
                let status = resp.data.result == "REJECTED - Transaction fail" ? "Payment cancelled by user" : resp.data.result == "EXPIRED - Transaction fail" ? "Payment timed out" :resp.data.result == "FAILED - Transaction fail" ? "FAILED - Transaction fail" : "Payment Completed"
                notify({ group: 'auth', type: 'error', title: `${status}` })
                commit('setIsHdfcPaymentLoader', false)
            } else if(resp.data.result == "SUCCESS") {
                notify({ group: 'auth', type: 'success', title: `${resp.data.result}` })
                commit('setIsHdfcPaymentLoader', false)
            } else if(resp.data.result?.includes('PENDING') && state.isHdfcPayment) {
                checkHdfcTimeout = setTimeout(() => {
                    dispatch('checkHdfcPayment')
                }, 5000);
            }
        } catch (error) {
            commit('setIsHdfcPaymentLoader', false)
            errHandle.statusCodeCheck(error)
        }
    },

    async boRedirection({ state, commit, rootGetters }) {
        commit('setIsBoRedirectLoader', true)
        await commonService.boRedirection().then(
            (res) => {
                if (res.status == 200 && res.data?.message == 'Success') {
                    const redirectUrl = res.data?.result[0].redirectUrl;
                    if(redirectUrl) {
                        window.open(redirectUrl)
                    } else {
                    }
                } else {
                    notify({ group: 'auth', type: 'error', title: `${res.data?.message}` })
                }
            },
            (error) => {
                commit('setIsBoRedirectLoader', false)
                errHandle.statusCodeCheck(error);
            }
        ).finally(() => {
            commit('setIsBoRedirectLoader', false)
        });
    },
    
    // razorpay create payment
    async createPayment_razorpay({ state, commit, rootGetters, dispatch }, payload) {
        commit('setIsPayinLoader', true)
        await commonService.createPayment_razorpay(payload.json).then(
            (res) => {
                if (res.status == 200 && res.data?.message == 'Success') {
                    dispatch('getPayment', { payment: res.data.result[0], data: payload.json, bank:payload.selectedReq })
                } else {
                    notify({ group: 'auth', type: 'error', title: `${res.data?.message}` })
                }
            },
            (error) => {
                errHandle.statusCodeCheck(error);
            }
        ).finally(() => {
            commit('setIsPayinLoader', false)
        });
    },

    async getPayment({ state, commit, dispatch, rootGetters }, payload) {
        var options = {
                "key": 'rzp_live_SZ6HSZtBNeMycO',
                "amount":state.currentPaymentAmount * 100,
                "currency": "INR",
                "name": "Pocket",
                "description": payload.data.segment,
                "image": favicon,
                "order_id": payload.payment.orderId,
                "handler": function (response) {
                        let json = {
                            "applicationId": rootGetters['login/getUCC'],
                            "razorpayOrderId": response.razorpay_order_id,
                            "razorpayPaymentId": response.razorpay_payment_id,
                            "razorpaySignature": response.razorpay_signature,
                            "receipt": payload.orderId,
                            "amount": state.currentPaymentAmount * 100
                        }
                        dispatch('verifyPayment_razorpay', json)
                },
                "method": {
                    "netbanking": true,
                    "upi": false,  
                },
                "netbanking": {
                    "bank": payload.bank.bankCode
                },
                "prefill": {
                    "contact": rootGetters['pref/getUserDetails'].mobNo,
                    'email': rootGetters['pref/getUserDetails'].email,
                },
                "notes": {
                    "address": "Razorpay Corporate Office"
                },
                "theme": {
                    "color": "#753ED7"
                },
                "modal": {
                    "ondismiss": function(e) {
                        notify({ group: 'auth', type: 'error', title: 'Payment cancelled by user' })
                    }
                }
            };
            var rzp1 = new Razorpay(options);
            rzp1.on('payment.failed', function (response){
                notify({ group: 'auth', type: 'error', title: `${response.error.description}` })
            });
            rzp1.open();
 
        

    },

    // razorpay verify payment
    async verifyPayment_razorpay({ state, commit, rootGetters, dispatch }, payload) {
        await commonService.verifyPayment_razorpay(payload).then(
            async(res) => {
                if (res.status == 200 && res.data?.message == 'Success') {
                    notify({ group: 'auth', type: 'success', title: `${res.data.result[0]}` })
                    commit('setIsPayin', false)
                    commit('setIsPayout', false)
                    await dispatch('getFunds', 'verify_payment')
                } else {
                    notify({ group: 'auth', type: 'error', title: `${res.data?.message}` })
                }
            },
            (error) => {
                errHandle.statusCodeCheck(error);
            }
        ).finally(() => {
        });
    },
};

const mutations = {
    setFunds(state, payload) {
        state.fundsData = payload
    },
    setComFunds(state, payload) {
        state.comFundsData = payload
    },
    setEqLoader(state, payload) {
        state.isEqLoader = payload
    },
    setComLoader(state, payload) {
        state.isComLoader = payload
    },
    setIsPayin(state, payload) {
        state.isPayin = payload
    },
    setIsPayout(state, payload) {
        state.isPayout = payload
    },
    setIsPayinLoader(state, payload) {
        state.isPayinLoader = payload
    },
    setIsPayoutLoader(state, payload) {
        state.isPayoutLoader = payload
    },
    setBanksList(state, payload) {
        state.banksList = payload
    },
    setSegmentList(state, payload) {
        state.segmentList = payload
        let segs = state.segmentList
        let filteredSegs = []
        if (segs?.length) {
            let isHaveEq = segs?.includes('NSE') || segs?.includes('NFO') || segs?.includes('CDS')
            let isHaveMcx = segs?.includes('MCX')
            let nseObj = { exchange: 'NSE - Equity/Derivatives/Currency', key: 'NSE' }
            let mcxObj = { exchange: 'MCX - Commodity', key: 'MCX' }
            if(isHaveEq) {
                filteredSegs.push(nseObj)
            }
            if(isHaveMcx) {
                filteredSegs.push(mcxObj)
            }
        }
        state.filteredSegments = filteredSegs;
    },
    setIsPayinDetailsLoader(state, payload) {
        state.isPayinDetailsLoader = payload
    },
    setIsPayoutDetailsLoader(state, payload) {
        state.isPayoutDetailsLoader = payload
    },
    setIsHdfcPaymentLoader(state, payload) {
        state.isHdfcPayment = payload
    },
    setHdfcOrderId(state, payload) {
        state.hdfcOrderId = payload
    },
    setFundTabs(state, payload) {
        state.fundsTabs = payload
    },
    reset_state(state) {
        Object.assign(state, {
            fundsData: '',
            fundsTabs: [],
            comFundsData: [],
            isEqLoader: false,
            isComLoader: false,
            isPayin: false,
            isPayout: false,
            isPayinLoader: false,
            isPayoutLoader: false,
            banksList: [],
            segmentList: [],
            isPayinDetailsLoader: false,
            isPayoutDetailsLoader: false,
            filteredSegments: [],
            isHdfcPayment: false,
            hdfcOrderId: ''
        });
    },
    setIsBoRedirectLoader(state, payload) {
        state.isBoRedirectLoader = payload
    },
    setCurrentPaymentAmount(state, payload) {
        state.currentPaymentAmount = payload
    }
};

const getters = {
    getFunds: state => state.fundsData,
    getFundTabs: state => state.fundsTabs,
    getComFunds: state => state.comFundsData,
    getEqLoader: state => state.isEqLoader,
    getComLoader: state => state.isComLoader,
    getIsPayin: state => state.isPayin,
    getIsPayout: state => state.isPayout,
    getIsPayinLoader: state => state.isPayinLoader,
    getIsPayoutLoader: state => state.isPayoutLoader,
    getBanksList: state => state.banksList,
    getSegmentList: state => state.segmentList,
    getIsPayinDetailsLoader: state => state.isPayinDetailsLoader,
    getIsPayoutDetailsLoader: state => state.isPayoutDetailsLoader,
    getFilteredSegments: state => state.filteredSegments,
    getIsHdfcPaymentLoader: state => state.isHdfcPayment,
    getHdfcOrderId: state => state.hdfcOrderId,
    getIsBoRedirectLoader: state => state.isBoRedirectLoader,
    getCurrentPaymentAmount: state => state.currentPaymentAmount
};

const funds = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default funds