import { env, loginAuthHead, authHeader } from '../_helpers';
import errHandle from '../handleError/errorHandling';
import axios from 'axios'
import store from "../_store/index"

const AXIOS = axios.create({
    baseURL: env().BASEURL
});

const EKYCAXIOS = axios.create({
    baseURL: env().EKYCBASEURL
});

const QRAXIOS = axios.create({
    baseURL: env().qrURL
});

export async function service_return(json) {
    let requestOptions = null
    if(json.authType) {
        requestOptions = {
            headers: json.authType == 'type_1' ? loginAuthHead() : authHeader(),
        }
    }
    let secondParam = json.params ? json.params : requestOptions
    if(json.axiosType == 'AXIOS') {
        return await AXIOS[json.method](`${json.module}/${json.path}`, secondParam, requestOptions).then((response) => {
            return response
        },(err) => {
                errHandle.statusCodeCheck(err , json?.from)
        }).finally(()=> {
            if(json.loaderMutation) {
                store.commit(json.loaderMutation, false)
            }
        })
    }else if(json.axiosType == 'QRAXIOS') {
        return await AXIOS[json.method](`${json.module}`, requestOptions).then((response) => {
            return response
        },(err) => {
            if(json?.from != 'router' || !json?.from) {
                errHandle.statusCodeCheck(err)
            }
        }).finally(()=> {
            if(json.loaderMutation) {
                store.commit(json.loaderMutation, false)
            }
        })
    } else {
        return await EKYCAXIOS[json.method](`${json.module}/${json.path}`, json.params, requestOptions).then((response) => {
            return response
        },(err) => {
            errHandle.statusCodeCheck(err)
        }).finally(()=> {
            if(json.loaderMutation) {
                store.commit(json.loaderMutation, false)
            }
        })
    }
}

